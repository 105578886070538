import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	Props,
	IPropsStore,
	ViewModeSym,
	ViewMode,
} from '@wix/thunderbolt-symbols'
import { SliderGalleryPageConfig } from './types'
import { name } from './symbols'
import {
	AnimationCallbacks,
	AnimationData,
	Animations,
	IAnimations,
	SequenceAnimationItemData,
} from 'feature-animations'
import fastdom from 'fastdom'

const sequences: { [sliderGalleryId: string]: any } = {}
const timeouts: { [sliderGalleryId: string]: any } = {}

const sliderGalleryFactory = (
	pageFeatureConfig: SliderGalleryPageConfig,
	propsStore: IPropsStore,
	viewMode: ViewMode,
	animationManager: IAnimations
): IPageWillMountHandler => {
	const play = async (
		galleryId: string,
		direction: 'left' | 'right',
		visibleItemsContainerRef: HTMLDivElement | null,
		allItemsContainerRef: HTMLDivElement | null
	) => {
		if (!visibleItemsContainerRef || !allItemsContainerRef) {
			return
		}
		const { loop, speed, margin = 0 } = pageFeatureConfig[galleryId]
		const isReverse = direction === 'left'
		const targetId = allItemsContainerRef.getAttribute('id') || ''
		const animator = await animationManager.getInstance()
		// kill prev sequence if exists
		await pause(galleryId, true)

		fastdom.measure(async () => {
			const visibleItemsContainerWidth = visibleItemsContainerRef.getBoundingClientRect().width || 0 // container width of visible images
			const allItemsContainerWidth = allItemsContainerRef.getBoundingClientRect().width || 0 // container width of all images
			const currentLeft = allItemsContainerRef.offsetLeft || 0
			const allItemsWidth = allItemsContainerWidth - margin
			const maxLeft = loop ? -allItemsWidth / 2 : visibleItemsContainerWidth - allItemsWidth
			const animationSpeedConstant = 60
			const duration = Math.abs(maxLeft) / (speed * animationSpeedConstant)
			const relativeDuration =
				duration * (isReverse ? Math.abs(currentLeft / maxLeft) : 1 - Math.abs(currentLeft / maxLeft))

			// Create first tween to bring us back to the starting point
			const animationData: AnimationData = {
				name: 'BasePosition',
				targetId,
				duration: relativeDuration,
				delay: 0,
				params: {
					from: { left: currentLeft },
					to: { left: isReverse ? 0 : maxLeft },
					ease: 'Linear.easeNone',
				},
			}
			const sequenceData: Array<SequenceAnimationItemData> = [{ type: 'Animation', data: animationData }]
			if (loop) {
				const loopAnimationData: AnimationData = {
					name: 'BasePosition',
					targetId,
					duration,
					delay: 0,
					params: {
						from: { left: isReverse ? maxLeft : 0 },
						to: { left: isReverse ? 0 : maxLeft },
						repeat: loop ? -1 : 0,
						immediateRender: false,
						ease: 'Linear.easeNone',
					},
				}
				sequenceData.push({ type: 'Animation', data: loopAnimationData })
			}

			const sequence = animator.runSequence(sequenceData, { paused: true }).timeline
			animator.animateTimeScale({ instance: sequence, duration: 1, from: 0, to: 1 })
			sequences[galleryId] = sequence

			// This is for parity with current product in bolt
			if (viewMode === 'mobile') {
				timeouts[galleryId] && clearTimeout(timeouts[galleryId])
				timeouts[galleryId] = setTimeout(() => {
					timeouts[galleryId] = undefined
					pause(galleryId)
				}, 2000)
			}
		})
	}

	const pause = async (galleryId: string, killImmediate: boolean = false) => {
		const animator = await animationManager.getInstance()
		const sequence = sequences[galleryId]
		if (sequence) {
			const callbacks: AnimationCallbacks = {}
			callbacks.onComplete = () => {
				if (sequence === sequences[galleryId]) {
					animator.kill(sequence)
					sequences[galleryId] = undefined
				}
			}
			if (!sequence.paused() && !killImmediate) {
				animator.animateTimeScale({ instance: sequence, duration: 1, from: 1, to: 0 }, callbacks)
			} else {
				animator.kill(sequence)
			}
		}
	}

	return {
		async pageWillMount() {
			Object.keys(pageFeatureConfig).forEach((galleryId) =>
				propsStore.update({
					[galleryId]: {
						play,
						pause,
					},
				})
			)
		},
	}
}

export const SliderGallery = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, ViewModeSym, optional(Animations)],
	sliderGalleryFactory
)
